@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Roboto Condensed', sans-serif;
    }
}

body, #root {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/*#modal_container {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background-color: transparent;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    z-index: 10000;*/
/*    pointer-events: none;*/
/*    overflow: scroll;*/
/*}*/

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    user-select: none;
}

.ant-layout-sider, .ant-layout-sider-trigger {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.leaflet-routing-container {
    display: none !important;
}

body > iframe {
    display: none;
}